import request from '@/utils/request'

/**
 * 信息唯一校验
 * @param {*} data 
 * @returns 
 * data:{
    field (string, optional): 字段名 ,
    ownId (string, optional): 排除自己，在编辑时有用，新增时不传递 ,
    value (string, optional): 值
 } 
 */
export function uniqueValidator (data) {
    return request({
        url: '/user/userbasic/uniqueValidator',
        method: 'post',
        data
    })
}

/**
 * 发送短信验证码(登录)
 * @param {*} data 
 * @returns 
 * data:{
    phone (string): 手机号 ,
 } 
 */
export function smsSendCode (data) {
    return request({
        url: '/smsSendCode',
        method: 'get',
        params: data
    })
}

/**
 * 发送短信验证码(新增或注册)
 * @param {*} data 
 * @returns 
 * data:{
    phone (string): 手机号 ,
 } 
 */
export function noUserCheckSendCode (data) {
    return request({
        url: '/noUserCheckSendCode',
        method: 'get',
        params: data
    })
}