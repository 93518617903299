<template>
    <el-dialog :title="op == 'add' ? '新增账号' : '修改账号'" :close-on-click-modal="false" :visible="dialogShow" @close="closeDialog">
        <el-form :model="form" ref="form" :rules="rules">
            <el-divider content-position="left">基本信息</el-divider>
            <el-form-item prop="userName" label="姓名" label-width="200px">
                <el-input :readonly="op != 'add'" style="width: 70%" v-model="form.userName" autocomplete="off" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item v-if="addType == 1" prop="regions" label="发布区域" label-width="200px">
                <el-cascader style="width: 70%" :props="{ multiple: true }" collapse-tags placeholder="请选择区域" size="large" v-model="form.regions" :options="options" clearable></el-cascader>
            </el-form-item>
            <el-form-item v-if="addType == 2" prop="phone" label="联系电话" label-width="200px">
                <el-input :readonly="op != 'add'" style="width: 70%" v-model="form.phone" autocomplete="off" clearable placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item prop="region" label="所在区域" label-width="200px">
                <el-cascader style="width: 70%" placeholder="请选择区域" size="large" v-model="form.region" :options="options" clearable></el-cascader>
            </el-form-item>
            <el-form-item prop="address" label="详细地址" label-width="200px">
                <el-input style="width: 70%" v-model="form.address" autocomplete="off" clearable placeholder="请输入详细地址"></el-input>
            </el-form-item>
            <el-form-item prop="remark" label="备注" label-width="200px">
                <el-input type="textarea" :rows="5" style="width: 70%" v-model="form.remark" autocomplete="off" placeholder="请输入备注（选填）"></el-input>
            </el-form-item>
            <el-divider v-if="addType == 1" content-position="left">联系信息</el-divider>
            <el-form-item v-if="addType == 1" prop="phone" label="联系电话" label-width="200px">
                <el-input :readonly="op != 'add'" :style="op == 'add' ? 'width: 54%' : 'width: 70%'" v-model="form.phone" autocomplete="off" clearable placeholder="请输入手机号码"></el-input>
                <el-button v-if="op == 'add'" :disabled="sendSmsText != '发送验证码'" type="primary" @click="sendSms" style="width: 16%">{{ sendSmsText }}</el-button>
            </el-form-item>
            <el-form-item v-if="addType == 1 && op == 'add'" prop="smsCode" label="手机验证码" label-width="200px">
                <el-input style="width: 70%" v-model="form.smsCode" autocomplete="off" clearable placeholder="请输入验证码"></el-input>
            </el-form-item>
            <el-divider content-position="left">账号设置</el-divider>
            <el-form-item prop="expirationTime" label="有效期" label-width="200px">
                <el-date-picker style="width: 70%" v-model="form.expirationTime" clearable　placeholder="请设置失效期"></el-date-picker>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { isPhone, isSmsCode, isChina } from "@/utils/validate";
import moment from "moment";
import { uniqueValidator, noUserCheckSendCode } from "@/api/common";
import { addByBelongUser, queryUserInfo, updateUser, queryRegionList } from "@/api/user";
import { solution } from "@/utils";

export default {
    props: ["visible", "op", "addType"],
    data() {
        const validateTelPhone = (rule, value, callback) => {
            if ("" == value) {
                callback(new Error("手机号码不能为空"));
            } else if (!isPhone(value)) {
                callback(new Error("手机号格式不正确"));
            } else {
                if (this.op != "add") {
                    callback();
                    return;
                }
                let params = {
                    field: "phone",
                    value,
                };
                uniqueValidator(params)
                    .then((res) => {
                        let flag = res.data;
                        if (flag) {
                            callback();
                        } else {
                            callback(new Error("该手机号已被注册，请更换手机号重新输入"));
                        }
                    })
                    .catch((err) => {
                        callback(new Error("手机号唯一校验失败，请稍后重试"));
                    });
            }
        };
        const verificationCode = (rule, value, callback) => {
            if ("" == value) callback(new Error("验证码不能为空"));
            if (!isSmsCode(value)) callback(new Error("验证码不正确"));
            else callback();
        };
        const verificationUserName = (rule, value, callback) => {
            if (this.op != "add" && +this.addType === 2) {
                callback();
            } else {
                if ("" == value) callback(new Error("用户名不能为空"));
                if (!isChina(value)) callback(new Error("用户名必须为中文，且最少两个字"));
                else callback();
            }
        };
        return {
            timer: null,
            sendSmsText: "发送验证码",
            moment,
            options: regionData,
            dialogShow: false,
            form: {
                userName: "",
                region: [], // 账号所在区域
                regions: [], // 区代账号发布区域
                address: "",
                phone: "",
                smsCode: "",
                expirationTime: moment().add(1, "years"),
            },
            rules: {
                regions: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择发布区域",
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        trigger: "change",
                        validator: verificationCode,
                    },
                ],
                userName: [
                    {
                        required: true,
                        trigger: "change",
                        validator: verificationUserName,
                    },
                ],
                region: [
                    {
                        required: true,
                        trigger: "change",
                        message: "所在区域不能为空！",
                    },
                ],
                address: [
                    {
                        required: true,
                        trigger: "change",
                        message: "详细地址不能为空！",
                    },
                ],
                expirationTime: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "失效期不能为空！",
                    },
                ],
                phone: [
                    {
                        required: true,
                        trigger: "change",
                        validator: validateTelPhone,
                    },
                ],
            },
        };
    },
    watch: {
        visible(data) {
            this.dialogShow = data;
            const _this = this;
            if (data && this.op != "add") {
                //查询详情
                _this.getUserInfo();
            }
            if (data) {
                if (this.userInfo.userType == 1) {
                    // 查询区代账号负责的所有区域
                    this.getRegion();
                }
            }
        },
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        getRegion() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            queryRegionList(params)
                .then((res) => {
                    let dataList = res.data.dataList;
                    const keys = ["province", "city", "district"];
                    // dataList 是一个扁平的数组，是需要转换成城市三级级联数组才能给级联器使用
                    let list = solution(dataList, keys);
                    list.map((pItem) => {
                        pItem.label = pItem.value;
                        pItem.value = TextToCode[pItem.label].code;
                        pItem.children.map((cItem) => {
                            cItem.label = cItem.value;
                            cItem.value = TextToCode[pItem.label][cItem.label].code;
                            cItem.children.map((dItem) => {
                                dItem.label = dItem.value;
                                dItem.value = TextToCode[pItem.label][cItem.label][dItem.label].code;
                            });
                        });
                    });
                    _this.options = list;
                })
                .catch((err) => {});
        },
        getUserInfo() {
            const _this = this;
            let params = {
                userCode: this.op,
            };
            const loading = _this.$loading({
                lock: true,
                text: "加载中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            queryUserInfo(params)
                .then((res) => {
                    loading.close();
                    this.$refs.form.resetFields();
                    _this.form = {
                        userName: res.data.userName,
                        region: [],
                        address: res.data.address,
                        phone: res.data.phone,
                        expirationTime: moment(res.data.expirationTime),
                        remark: res.data.remark,
                    };
                    if (TextToCode[res.data.province].code && TextToCode[res.data.province][res.data.city].code && TextToCode[res.data.province][res.data.city][res.data.district].code) {
                        _this.form.region = [TextToCode[res.data.province].code, TextToCode[res.data.province][res.data.city].code, TextToCode[res.data.province][res.data.city][res.data.district].code];
                    }
                    if (this.addType == 1 && res.data.poList) {
                        let regions = [];
                        res.data.poList.map((item) => {
                            let arr = [TextToCode[item.province].code, TextToCode[item.province][item.city].code, TextToCode[item.province][item.city][item.district].code];
                            regions.push(arr);
                        });
                        _this.form.regions = regions;
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        sendSms() {
            const _this = this;
            this.$refs.form.validateField("phone", (valid) => {
                if (valid == "") {
                    let params = {
                        phone: _this.form.phone,
                    };
                    noUserCheckSendCode(params).then((res) => {
                        let num = 60;
                        _this.sendSmsText = num + "S";
                        _this.timer = setInterval(() => {
                            num -= 1;
                            _this.sendSmsText = num + "S";
                            if (num == 0) {
                                clearInterval(_this.timer);
                                _this.sendSmsText = "发送验证码";
                            }
                        }, 1000);
                    });
                }
            });
        },
        submit() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let params = {
                        userName: _this.form.userName,
                        phone: _this.form.phone,
                        province: CodeToText[_this.form.region[0]],
                        city: CodeToText[_this.form.region[1]],
                        district: CodeToText[_this.form.region[2]],
                        address: _this.form.address,
                        remark: _this.form.remark || "",
                        userType: _this.addType,
                        belongToCode: _this.userInfo.userCode,
                        source: 2,
                        expirationTime: moment(_this.form.expirationTime).format("YYYY-MM-DD"),
                    };
                    if (_this.op == "add") {
                        // 新增
                        if (_this.addType == 1) {
                            let regions = [];
                            _this.form.regions.map((item) => {
                                let obj = {
                                    province: CodeToText[item[0]],
                                    city: CodeToText[item[1]],
                                    district: CodeToText[item[2]],
                                };
                                regions.push(obj);
                            });
                            params.regions = regions;
                            params.smsCode = _this.form.smsCode;
                        }
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        addByBelongUser(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch((err) => {
                                loading.close();
                            });
                    } else {
                        // 更新
                        params = {
                            province: CodeToText[_this.form.region[0]],
                            city: CodeToText[_this.form.region[1]],
                            district: CodeToText[_this.form.region[2]],
                            address: _this.form.address,
                            remark: _this.form.remark || "",
                            expirationTime: moment(_this.form.expirationTime).format("YYYY-MM-DD"),
                            userCode: _this.op,
                        };
                        if (_this.addType == 1) {
                            let regions = [];
                            _this.form.regions.map((item) => {
                                let obj = {
                                    province: CodeToText[item[0]],
                                    city: CodeToText[item[1]],
                                    district: CodeToText[item[2]],
                                };
                                regions.push(obj);
                            });
                            params.regions = regions;
                        }
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        updateUser(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch(() => {
                                loading.close();
                            });
                    }
                }
            });
        },
        closeDialog(flag) {
            this.$emit("onDialogClose", flag);
            this.$refs.form.resetFields();
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.sendSmsText = "发送验证码";
        },
    },
};
</script>
