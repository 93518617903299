<template>
	<el-dialog :visible="visible" @close="closeDialog">
		<div class="protocols">
			<div class="title">外包劳务合同书</div>
			<div>甲方：湖南星华信息科技有限公司</div>
			<div class="PartyB">
				<span>乙方：</span>
				<img class="nameImg" :src="`${resources_host}${record.contractSignUrl}`" />
			</div>
			<div>甲乙双方经平等协商一致，自愿签订本劳务协议，共同遵守本协议所列条款。</div>
			<div>第一条 本协议自合同签订之日起生效，即乙方在甲方的系统上注册认证之日。</div>
			<div>第二条 乙方承担的劳务内容为：按照甲方的需求真实、完整、清晰的拍摄户外广告以及定位准确。</div>
			<div>第三条 甲方对乙方拍摄的照片进行审核，审核通过的照片按照甲方系统上的显示的具体价格计算，未审核通过的照片不计算费用。甲方支付乙方劳务报酬的方法：银行转账。</div>
			<div>第四条 乙方依据本协议第二条约定的劳务内容、要求、方法为甲方提供劳务，乙方依据第三条承担所约定的劳务费。</div>
			<div>第五条 乙方负有保守甲方商业秘密的义务。</div>
			<div>第六条 发生下列情形之一，本协议终止：一、双方就解除本协议协商一致的;二、乙方由于健康原因不能履行本协议义务的；三、由于不可抗力致使合同中止的。</div>
			<div>第七条 甲、乙双方若单方面解除本协议，需提前一周通知另一方。</div>
			<div>第八条 本协议终止、解除后，乙方应在一周内将有关工作向甲方移交完毕，如给甲方造成损失、应予赔偿。</div>
			<div>第九条 依据本协议第七条、第八条约定终止或解除本协议，不支付任何赔偿。</div>
			<div>第十条 因本协议引起的或与本协议有关的任何争议，均提请岳麓区人民法院进行仲裁。仲裁裁决是终局的，对双方均有约束力。</div>
			<div>第十一条 本合同一式两份，甲乙双方各执一份。</div>
			<div class="signature">
				<span>乙方：</span>
				<img class="nameImg" :src="`${resources_host}${record.contractSignUrl}`" />
			</div>
			<div class="time">{{moment(record.contractSignTime).format('YYYY年MM月DD日')}}</div>
		</div>
	</el-dialog>
</template>

<script>
import moment from 'moment';
export default {
	data() {
		return {
			moment,
			visible: false,
			record: {},
			resources_host: process.env.VUE_APP_RESOURCES_HOST
		};
	},
	created() {},
	mounted() {},
	methods: {
		init(row, visible) {
			this.record = row;
			this.visible = true;
		},
		closeDialog() {
			this.visible = false;
		}
	}
};
</script>

<style scoped lang="less">
.protocols {
	& > div {
		padding: 5px 12px;
		font-size: 16px;
		color: #333333;
		line-height: 32px;
	}
	.title {
		font-size: 18px;
		text-align: center;
		font-weight: 600;
	}
	.PartyB {
		height: 44px;
		display: flex;
		align-items: center;
	}
	.signature {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.time {
		text-align: right;
	}
	.nameImg {
		height: 44px;
		width: 100px;
	}
}
</style>
